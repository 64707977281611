<template>
  <div class="main">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>按钮数据</span>
        <div style="margin-top: 18px; display: flex; flex-wrap: wrap">
          <div class="">
            <span class="label">点击时间： </span>
            <el-date-picker
                size="small"
                v-model="filterData.created_at"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 400px"
            />
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap; margin-top: 10px">
          <el-button
            size="small"
            type="primary"
            @click="query(1)"
            icon="el-icon-search"
          >
            查询
          </el-button>
          <el-button
            size="small"
            @click="filterData = { page: 1 }"
            icon="el-icon-plus"
          >
            重置
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        size="mini"
        :cell-style="{ paddingTop: '12px', paddingBottom: '10px' }"
      >
        <el-table-column prop="btn_name" label="按钮名称" />
        <el-table-column prop="click_user_num" label="点击人数" />
        <el-table-column prop="click_btn_num" label="点击次数" />
      </el-table>
      <el-row>
        <el-col :span="24" style="text-align: center; margin-top: 10px">
          <el-pagination
            :page-sizes="[10]"
            :page-size="10"
            background
            layout="total,sizes,prev, pager, next"
            @current-change="pageChange"
            :current-page="filterData.page"
            :total="total"
          ></el-pagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      form: {},
    };
  },

  created() {
    this.query();
  },

  methods: {
    pageChange(page) {
      this.filterData.page = page;
      this.query();
    },
    query(page) {
      if (page) this.filterData.page = page;
      this.$http
        .post("/admin/stats/btn", this.filterData)
        .then((res) => {
          if (res.status === 200) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
</style>
